import { useEffect } from 'react'
import { useQuery } from 'react-query'
import useApi from '../hooks/useApi'
import { AppHealthTile } from './AppHealthTile'

export const Home = () => {
  const { getHealthChecks } = useApi()
  const { data: arData } = useQuery(['arHealthChecks'], getHealthChecks)

  useEffect(() => {
    document.title = 'App Health Dashboard'
  }, [])

  return (
    <div className="container mx-auto px-5 py-10 font-mono">
      <h1 className="mb-10 text-center text-4xl font-bold text-white">
        App Health Dashboard
      </h1>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
        <AppHealthTile
          name="Asana Rebel"
          link="/asana-rebel"
          healthChecks={arData?.health_checks}
        />
        <AppHealthTile name="Perfect Day" link="/perfect-day" />
        <AppHealthTile name="Visual Mind" link="/visual-mind" />
        <AppHealthTile name="Filterly" link="/filterly" />
        <AppHealthTile name="ChatAI Plus" link="/chatai-plus" />
      </div>
    </div>
  )
}
