interface HealthChecksResponse {
  message: string
  health_checks: HealthChecksObject
}

export interface HealthChecksObject {
  [key: string]: HealthChecksEntity
  today_tab: HealthChecksEntity
  webfunnel: HealthChecksEntity
  sidekiq: HealthChecksEntity
  purchases_web: HealthChecksEntity
  ab_testing_system: HealthChecksEntity
}

interface HealthChecksEntity {
  up: boolean
  response_time?: number
  job_count?: number
  purchases_count?: number
  proceeds?: string
  signups_count?: number
}

const API_BASE_URL = process.env.REACT_APP_ASANA_REBEL_API_URL!

export default function useApi() {
  const requestQueryString = async (
    method: string,
    path: string,
    data: object = {},
    authToken = '',
  ) => {
    const params = new URLSearchParams(Object.assign(data) || {}).toString()
    const res = await fetch(`${API_BASE_URL}${path}?${params}`, {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...(authToken && { Authorization: `Bearer ${authToken}` }),
      },
    })

    return res.json()
  }

  const getHealthChecks = (): Promise<HealthChecksResponse> =>
    requestQueryString('GET', '/api/v1/health_checks')

  return {
    getHealthChecks,
  }
}
