import { useEffect } from 'react'
import { useQuery } from 'react-query'
import {
  FunnelIcon,
  BeakerIcon,
  CurrencyEuroIcon,
  CreditCardIcon,
  UserGroupIcon,
  CpuChipIcon,
  DevicePhoneMobileIcon,
} from '@heroicons/react/24/solid'
import { ReactComponent as GoogleIcon } from '../icons/google.svg'
import { ReactComponent as ArLogoIcon } from '../icons/ar-logo.svg'
import { ReactComponent as OutbrainIcon } from '../icons/outbrain.svg'
import useApi, { HealthChecksObject } from '../hooks/useApi'

interface ServicesMapping {
  [key: string]: {
    title: string
    icon: (props: { className: string }) => JSX.Element
  }
}

const UPDATE_INTERVAL = 60000
const SERVICES_MAPPING: ServicesMapping = {
  webfunnel: {
    title: 'Webfunnel (get)',
    icon: (props) => <FunnelIcon {...props} />,
  },
  organic_funnel: {
    title: 'Organic Funnel (join)',
    icon: (props) => <ArLogoIcon {...props} />,
  },
  google_funnel: {
    title: 'Google Funnel (try)',
    icon: (props) => <GoogleIcon {...props} />,
  },
  outbrain_funnel: {
    title: 'Outbrain Funnel (go)',
    icon: (props) => <OutbrainIcon {...props} />,
  },
  sidekiq: {
    title: 'Background Jobs',
    icon: (props) => <CpuChipIcon {...props} />,
  },
  today_tab: {
    title: 'Today Tab',
    icon: (props) => <DevicePhoneMobileIcon {...props} />,
  },
  ab_testing_system: {
    title: 'A/B Testing System',
    icon: (props) => <BeakerIcon {...props} />,
  },
  signups_web: {
    title: 'Signups Web (1h)',
    icon: (props) => <UserGroupIcon {...props} />,
  },
  purchases_web: {
    title: 'Purchases Web (1h)',
    icon: (props) => <CreditCardIcon {...props} />,
  },
  proceeds_web: {
    title: 'Proceeds Web (1h)',
    icon: (props) => <CurrencyEuroIcon {...props} />,
  },
}

export const AsanaRebel = () => {
  const { getHealthChecks } = useApi()

  const {
    dataUpdatedAt,
    isLoading,
    isError: backendError,
    data,
  } = useQuery(['healthChecks'], getHealthChecks, {
    refetchInterval: UPDATE_INTERVAL,
    refetchOnWindowFocus: 'always',
    retry: 3,
  })

  const healthChecks = data?.health_checks as HealthChecksObject

  const formatStatus = (healthCheck: string): string => {
    const { up } = healthChecks[healthCheck]
    return up === true ? '🟢' : '🔴'
  }

  const upColor = (healthCheck: string): string => {
    const { up } = healthChecks[healthCheck]
    return up === true ? 'text-green-400' : 'text-red-400'
  }

  const formatResponseTime = (healthCheck: string): string | undefined => {
    return healthChecks[healthCheck].response_time?.toFixed(2)
  }

  const responseTimeColor = (healthCheck: string): string => {
    const { response_time } = healthChecks[healthCheck]

    if (!response_time) {
      return 'text-neutral-400'
    } else if (response_time >= 2000 && response_time < 10000) {
      return 'text-orange-400'
    } else if (response_time > 10000) {
      return 'text-red-400'
    } else {
      return 'text-green-400'
    }
  }

  const getHealthRatio = (): string => {
    if (!healthChecks) return '0/0'

    const total = Object.keys(healthChecks).length
    const healthy = Object.values(healthChecks).filter(
      (check) => check.up === true,
    ).length

    return `${healthy}/${total}`
  }

  const getOverallHealth = (): string => {
    if (!healthChecks) return '⚪️'

    const total = Object.keys(healthChecks).length
    const healthy = Object.values(healthChecks).filter(
      (check) => check.up === true,
    ).length

    if (healthy === 0) return '🔴'
    if (healthy === total) return '🟢'
    return '🟡'
  }

  useEffect(() => {
    document.title = `${getOverallHealth()} AR Health`
  }, [])

  if (isLoading) {
    return (
      <div className="flex h-full min-h-screen w-full flex-col justify-center bg-neutral-900 font-mono">
        <p className="animate-pulse self-center text-5xl font-bold text-white">
          Loading ...
        </p>
      </div>
    )
  }

  if (backendError) {
    return (
      <div className="flex h-full min-h-screen w-full flex-col justify-center bg-neutral-900 font-mono">
        <p className="animate-pulse self-center text-5xl font-bold text-red-400">
          Backend Error
        </p>
        <p className="mt-5 self-center text-sm text-neutral-400">
          The Asana Rebel Backend might be down, please contact the team.
        </p>
      </div>
    )
  }

  return (
    <div className="flex h-full min-h-screen w-full flex-col bg-neutral-900 font-mono">
      <div className="flex w-full flex-col justify-center self-center px-5 py-10 2xl:w-2/3">
        <h1 className="self-center text-3xl font-bold text-white">
          Asana Rebel Health
          <span className="animate-ping text-neutral-600">|</span>
        </h1>
        <p className="self-center text-sm text-neutral-400">
          Last Updated: {new Date(dataUpdatedAt).toLocaleTimeString('de')}
        </p>

        <div className="mt-10 grid w-full grid-cols-1 justify-center gap-5 self-center md:grid-cols-3">
          {Object.keys(healthChecks).map((key, index) => {
            return (
              <div
                key={`service-${index}`}
                className="relative flex h-52 w-full flex-col justify-center border border-neutral-600">
                {SERVICES_MAPPING[key]?.icon({
                  className: 'absolute text-neutral-400 h-8 w-8 top-3 left-3',
                })}
                <p className={`self-center text-xl font-bold ${upColor(key)}`}>
                  {`${SERVICES_MAPPING[key]?.title} ${formatStatus(key)}`}
                </p>
                {healthChecks[key]?.response_time && (
                  <p className="self-center text-sm text-neutral-400">
                    Response Time:{' '}
                    <span className={responseTimeColor(key)}>
                      {formatResponseTime(key)}ms
                    </span>
                  </p>
                )}
                {(healthChecks[key]?.job_count as number) >= 0 && (
                  <p className="self-center text-sm text-neutral-400">
                    {`Waiting Jobs: ${healthChecks[key].job_count}`}
                  </p>
                )}
                {(healthChecks[key]?.purchases_count as number) >= 0 && (
                  <p className="self-center text-sm text-neutral-400">
                    {`Number of Purchases: ${healthChecks[key].purchases_count}`}
                  </p>
                )}
                {(healthChecks[key]?.proceeds as string) && (
                  <p className="self-center text-sm text-neutral-400">
                    {`Total Proceeds: ${healthChecks[key].proceeds}`}
                  </p>
                )}
                {(healthChecks[key]?.signups_count as number) >= 0 && (
                  <p className="self-center text-sm text-neutral-400">
                    {`Number of Signups: ${healthChecks[key].signups_count}`}
                  </p>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
