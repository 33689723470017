import { Link } from 'react-router-dom'
import { HealthChecksObject } from '../hooks/useApi'

interface AppHealthTileProps {
  name: string
  link: string
  healthChecks?: HealthChecksObject
}

export const AppHealthTile = ({
  name,
  link,
  healthChecks,
}: AppHealthTileProps) => {
  const getOverallHealth = (): string => {
    if (!healthChecks) return '⚪️'

    const total = Object.keys(healthChecks).length
    const healthy = Object.values(healthChecks).filter(
      (check: any) => check.up === true,
    ).length

    if (healthy === 0) return '🔴'
    if (healthy === total) return '🟢'
    return '🟡'
  }

  return (
    <Link
      to={link}
      className="relative flex h-40 w-full flex-col justify-center border border-neutral-600 font-mono transition-colors hover:border-neutral-400">
      <div className="flex flex-row items-center self-center text-center">
        <h2 className="mb-2 text-xl font-bold text-white">
          {name} {getOverallHealth()}
        </h2>
      </div>
    </Link>
  )
}
